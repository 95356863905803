import { atom, selector } from "recoil";
import { localStorageEffect } from "./effects";
import { activeGroupState } from "./state";
import { defaultGroupId, hostDefaultState } from "../lib/defaults";

const defaultHosts = {
  [defaultGroupId]: hostDefaultState,
};
export const hostState = atom({
  key: "host",
  default: defaultHosts,
  effects: [localStorageEffect("host", defaultHosts)],
});

export const hostByGroupIdSelector = selector({
  key: "hostByGroupId",
  get: ({ get }) => {
    const groupId = get(activeGroupState);
    const hosts = get(hostState);
    return hosts[groupId];
  },
  set: ({ get, set }, newHost) => {
    const groupId = get(activeGroupState);
    const hosts = get(hostState);
    set(hostState, { ...hosts, [groupId]: newHost });
  },
});
