import { atom, selector } from "recoil";
import { localStorageEffect } from "./effects";
import { defaultGroupId, defaultGuest } from "../lib/defaults";
import { activeGroupState } from "./state";

const defaultGuestsState = {
  [defaultGroupId]: [defaultGuest],
};
export const guestsState = atom({
  key: "guests",
  default: defaultGuestsState,
  effects: [localStorageEffect("guests", defaultGuestsState)],
});

export const guestsByGroupIdSelector = selector({
  key: "guestsByGroupId",
  get: ({ get }) => {
    const groupId = get(activeGroupState);
    const guests = get(guestsState);
    return guests[groupId];
  },
  set: ({ get, set }, newGuests) => {
    const groupId = get(activeGroupState);
    const guests = get(guestsState);
    set(guestsState, { ...guests, [groupId]: newGuests });
  },
});

export const allGuestsState = atom({
  key: "allGuests",
  default: {},
  effects: [localStorageEffect("allGuests", {})],
});

export const filteredGuestsSelector = selector({
  key: "filteredGuests",
  get: ({ get }) => {
    const guests = get(guestsByGroupIdSelector);
    const allGuests = get(allGuestsState);

    // Create a Set of guest IDs for efficient lookup
    const guestIds = new Set(guests.map((guest) => guest.id));

    // Filter allGuests to exclude guests in the Set
    return Object.values(allGuests).filter((guest) => !guestIds.has(guest.id));
  },
});
