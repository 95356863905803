import { atom, selector } from "recoil";
import { DateTime } from "luxon";
import { defaultSelectedDate } from "../lib/defaults";
import { hostByGroupIdSelector } from "./host";
import { groupsState, activeGroupState } from "./state";
import { tableStartTimeState } from "./settings";

export const currentDateTimeState = atom({
  key: "currentDateTime",
  default: DateTime.now().toMillis(),
});

const defaultSelectedDateSelector = selector({
  key: "defaultSelectedDateSelector",
  get: ({ get }) => {
    const groups = get(groupsState);
    const tableStartTime = get(tableStartTimeState);
    const host = get(hostByGroupIdSelector);

    let selectedDate = {};

    for (const group of groups) {
      selectedDate[group.id] = defaultSelectedDate(
        tableStartTime,
        host.timezone.timezone
      );
    }
    return selectedDate;
  },
});

export const selectedDateState = atom({
  key: "selectedDate",
  default: defaultSelectedDateSelector,
});

export const selectedDateByGroupIdSelector = selector({
  key: "selectedDateByGroup",
  get: ({ get }) => {
    const groupId = get(activeGroupState);
    const dates = get(selectedDateState);
    return dates[groupId];
  },
  set: ({ get, set }, newDateStr) => {
    const groupId = get(activeGroupState);
    const dates = get(selectedDateState);
    set(selectedDateState, { ...dates, [groupId]: newDateStr });
  },
});

// return Luxon date object for selected date, in Host timezone
export const selectedDateObjSelector = selector({
  key: "selectedDateObj",
  get: ({ get }) => {
    const dateStr = get(selectedDateByGroupIdSelector);
    const host = get(hostByGroupIdSelector);
    return DateTime.fromISO(dateStr).setZone(host.timezone.timezone);
  },
});
