import { DialogTitle, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export const CustomDialogTitle = (props) => {
  const { title, id, dataTestid, onClose } = props;

  return (
    <DialogTitle id={id}>
      {title}
      <IconButton
        data-testid={dataTestid}
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>
  );
};
