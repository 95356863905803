import { Dialog } from "@mui/material";
import { CustomDialogActions } from "./CustomDialogActions";
import { CustomDialogTitle } from "./CustomDialogTitle";

export const CustomDialog = (props) => {
  const { open, onClose, titleProps, actionProps, children, ...params } = props;

  return (
    <Dialog open={open} onClose={onClose} scroll="paper" {...params}>
      <CustomDialogTitle {...titleProps} />
      {children}
      <CustomDialogActions {...actionProps} />
    </Dialog>
  );
};
