import { Box } from "@mui/material";
import { useRecoilState, useRecoilValue } from "recoil";
import { DateTime } from "luxon";
import { currentDateTimeState, selectedDateObjSelector } from "../state/date";
import { hostByGroupIdSelector } from "../state/host";
import { guestsByGroupIdSelector } from "../state/guest";
import { TimeTable } from "./TimeTable";
import { generateTimeArray } from "../lib/timeUtils";
import { useCheckIfMobile } from "../hooks/useCheckIfMobile";
import { slotIntervalState, timeFormatState } from "../state/settings";

export function TimezoneSelectionArea(props) {
  const { readOnly = false, statesFromUrl = null } = props;
  const isMobile = useCheckIfMobile();

  const host = useRecoilValue(hostByGroupIdSelector);
  const [guests, setGuests] = useRecoilState(guestsByGroupIdSelector);
  const selectedDateObj = useRecoilValue(selectedDateObjSelector);

  const slotInterval = useRecoilValue(slotIntervalState);

  const currentDateTimeObj = DateTime.fromMillis(
    useRecoilValue(currentDateTimeState)
  );
  const timeFormat = useRecoilValue(timeFormatState);

  const hostTimeArray = generateTimeArray({
    startDate: selectedDateObj,
    currentDateTime: currentDateTimeObj,
    interval: { minutes: 60 },
    timeFormat,
  });

  const guestsTimeArray = guests.map((guest) =>
    generateTimeArray({
      startDate: selectedDateObj.setZone(guest.timezone.timezone),
      currentDateTime: currentDateTimeObj,
      interval: { minutes: 60 },
      timeFormat,
    })
  );

  return (
    <Box overflow="auto">
      <TimeTable
        host={host}
        guests={guests}
        removeGuest={(guestId) =>
          setGuests(guests.filter((e) => e.id !== guestId))
        }
        currentDateTimeObj={currentDateTimeObj}
        hostTimeArray={hostTimeArray}
        guestsTimeArray={guestsTimeArray}
        isMobile={isMobile}
        slotInterval={slotInterval}
        readOnly={readOnly}
        statesFromUrl={statesFromUrl}
      />
    </Box>
  );
}
