import { useEffect, useState, useRef } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { Paper, Table, TableContainer, Button, Box } from "@mui/material";
import { DateTime } from "luxon";

import { style, tableStyle } from "../theme";
import { getSlotsCount } from "../lib/timeUtils";
import { useAppendSnack } from "../hooks/useAppendSnack";

import { selectedSlotsByGroupIdSelector } from "../state/slots";
import { filteredViewEnabledState } from "../state/settings";
import { sessionSourceState } from "../state/state";

import { removeSlot, addSlot, isSelectedSlot } from "../lib/timeUtils";
import { colors } from "../lib/colors";
import { getTopPosition, getRowHeight, trackEvent } from "../lib/utils";

import { FilteredTimeTableBody } from "./FilteredTimeTableBody";
import { DefaultTimeTableBody } from "./DefaultTimeTableBody";
import { TimeTableHead } from "./TimeTableHead";
import { useAddGroup } from "../hooks/useGroupManagement";

const getRowIndex = (time, tableStartTime) => {
  return time.diff(tableStartTime, "minutes").minutes / 15;
};

const getTableStartEndTime = (hostTimeArray) => {
  const tableStartTime = hostTimeArray[0].timeInZone;

  let tableEndTime = hostTimeArray[hostTimeArray.length - 1].timeInZone;
  tableEndTime = tableEndTime.plus({ minutes: 60 });

  return {
    tableStartTime,
    tableEndTime,
  };
};

const filterSlots = (selectedSlots, hostTimeArray) => {
  const { tableStartTime, tableEndTime } = getTableStartEndTime(hostTimeArray);

  const filteredSlots = [];

  Object.entries(selectedSlots).forEach(([startKey, endKey]) => {
    const startTime = DateTime.fromISO(startKey);
    const endTime = DateTime.fromISO(endKey);

    // Check if the slot is within the specified date range
    if (startTime >= tableStartTime && startTime < tableEndTime) {
      const rowCount = endTime.diff(startTime, "minutes").minutes / 15;
      const startRowIndex = getRowIndex(startTime, tableStartTime);

      filteredSlots.push({
        startTime,
        endTime,
        rowCount,
        startRowIndex,
      });
    }
  });

  // Sort the array by start_time in ascending order
  return filteredSlots.sort((a, b) => a.start_time - b.start_time);
};

const renderFilteredSlots = (slots, tableWidth) => {
  return slots.map((slot, index) => {
    const { startTime, endTime, rowCount, startRowIndex } = slot;

    const rowHeight = getRowHeight(rowCount);
    const topPosition = getTopPosition(startRowIndex);

    return (
      <Box
        key={index}
        sx={{
          width: tableWidth,
          boxSizing: "border-box",
          position: "absolute",
          backgroundColor: colors.timeSlot.selected,
          border: `1px solid ${colors.timeSlot.slotBorders}`,
          borderRadius: "5px",
          top: `${topPosition}px`,
          height: `${rowHeight}px`,
          pointerEvents: "none", // Ignore all pointer events
          zIndex: 3,
        }}
      />
    );
  });
};

const getCurrentTimePosition = (currentDateTimeObj, hostTimeArray) => {
  const firstAvailablePresentSlot = currentDateTimeObj.plus({
    minutes: 15 - (currentDateTimeObj.toFormat("mm") % 15),
  });

  const { tableStartTime, tableEndTime } = getTableStartEndTime(hostTimeArray);

  if (
    firstAvailablePresentSlot < tableStartTime ||
    firstAvailablePresentSlot >= tableEndTime
  ) {
    return null;
  }

  const rowIndex = getRowIndex(firstAvailablePresentSlot, tableStartTime);

  // position 2px above the start time
  return getTopPosition(rowIndex) - 2;
};

// removeGuest should be a function that accepts a guest id and is triggered
// when the close button is tapped on the guest column
export function TimeTable(props) {
  const {
    host,
    guests,
    removeGuest,
    currentDateTimeObj,
    hostTimeArray,
    guestsTimeArray,
    isMobile,
    slotInterval,
    readOnly = false,
    statesFromUrl = null,
  } = props;

  const [selectedSlots, setSelectedSlots] = useRecoilState(
    selectedSlotsByGroupIdSelector
  );

  const [sessionSource, setSessionSource] = useRecoilState(sessionSourceState);

  const filteredViewEnabled = useRecoilValue(filteredViewEnabledState);
  const [showSlotsCount, setShowSlotsCount] = useState(false);

  const addGroup = useAddGroup();
  const appendSnack = useAppendSnack();

  const tableRef = useRef(null);
  const hoverBoxRef = useRef(null);
  const [tableWidth, setTableWidth] = useState("100%");

  const updateWidth = () => {
    if (tableRef.current) {
      setTableWidth(tableRef.current.scrollWidth + "px");
    }
  };

  useEffect(() => {
    // Update the width when the component mounts
    updateWidth();

    // Handle window resizing
    window.addEventListener("resize", updateWidth);

    // Create a ResizeObserver to detect changes in the table's size
    const resizeObserver = new ResizeObserver(updateWidth);

    // Observe the table
    if (tableRef.current) {
      resizeObserver.observe(tableRef.current);
    }

    if (!["Share", "Event Sent"].includes(sessionSource)) {
      trackEvent(`SESSION_OPEN_${currentDateTimeObj.hour}`, isMobile);
      setSessionSource("Event Sent");
    }

    // Clean up the event listener and observer on unmount
    return () => {
      window.removeEventListener("resize", updateWidth);

      if (tableRef.current) {
        resizeObserver.unobserve(tableRef.current);
      }
    };
  }, []); // empty dependency array ensures the effect runs only once after the initial render

  const slotsCountMessage = () => {
    const slotsCount = getSlotsCount(selectedSlots);

    return slotsCount < 2
      ? `${slotsCount} slot selected`
      : `${slotsCount} slots selected`;
  };

  useEffect(() => {
    if (showSlotsCount) {
      appendSnack(slotsCountMessage());
      setShowSlotsCount(false);
    }
  }, [selectedSlots]);

  const deselect = (hostTime, interval) => {
    const newSlots = removeSlot({
      selectedSlots: selectedSlots,
      time: hostTime.timeInZone.plus({ minutes: interval }),
    });

    setSelectedSlots(newSlots);
  };

  const select = (hostTime, interval) => {
    const newSlots = addSlot({
      selectedSlots: selectedSlots,
      time: hostTime.timeInZone.plus({ minutes: interval }),
      slotInterval: slotInterval,
    });

    setSelectedSlots(newSlots);
  };

  const handleRowClick = (hostTime, interval = 0) => {
    const { isSelected } = isSelectedSlot({
      selectedSlots: selectedSlots,
      time: hostTime.timeInZone.plus({ minutes: interval }),
    });

    if (!isSelected) {
      if (
        hostTime.timeInZone.plus({ minutes: interval }) < currentDateTimeObj
      ) {
        appendSnack("Oops! This time slot is in the past");
        return;
      }

      // add timeslot
      select(hostTime, interval);
    } else {
      // remove previously selected timeslot
      deselect(hostTime, interval);
    }

    // 1) after select / deselect, we need to show the updated count for selectedSlots.
    //    we can't call `appendSnack` here, as it will show the previous count as setRecoilState is not instantaneous.
    //    so we move `appendSnack` to inside useEffect, which gurantees `selectedSlots` has already been updated.
    // 2) This logic is not needed for other static snackbar message, eg: `Oops! This time slot is in the past`.
    // 3) Besides select / deselect, `selectedSlots` is reset in, eg: `clear all selections` and update host's timezone.
    //    Since useEffect above depends on `selectedSlots`, we need to differentiate where does the trigger comes from.
    //    So we introduce `showSlotsCount` flag here.
    setShowSlotsCount(true);
  };

  const handleOpenInZonejam = () => {
    addGroup(statesFromUrl);
    trackEvent(`SESSION_LINK_OPEN_${currentDateTimeObj.hour}`, isMobile);
    window.open(window.location.origin, "_blank");
  };

  const filteredSlots = filterSlots(selectedSlots, hostTimeArray);
  const currentTimePosition = getCurrentTimePosition(
    currentDateTimeObj,
    hostTimeArray
  );

  const filteredView = filteredViewEnabled || readOnly;

  return (
    <>
      <TableContainer
        sx={{
          ...(isMobile && style.noScrollbar),
          maxHeight: "100%",
          maxWidth: "100vw",
          overflow: "scroll",
          boxShadow: "none",
        }}
        component={Paper}
      >
        <div style={{ position: "relative" }}>
          {!filteredView && (
            <>
              {/* red line to indicate current time */}
              {currentTimePosition !== null && (
                <div
                  style={{
                    width: tableWidth, // Set the measured width
                    height: "2px",
                    backgroundColor: "red",
                    position: "absolute",
                    zIndex: 5,
                    top: `${currentTimePosition}px`,
                  }}
                />
              )}

              {/* highlight box for mouse hover */}
              <Box
                ref={hoverBoxRef}
                sx={{
                  width: tableWidth,
                  boxSizing: "border-box",
                  visibility: "hidden",
                  position: "absolute",
                  backgroundColor: colors.timeSlot.hover,
                  border: `1px solid ${colors.timeSlot.slotBorders}`,
                  borderRadius: "5px",
                  pointerEvents: "none", // Ignore all pointer events
                  zIndex: 4,
                }}
              />

              {/* highlight boxes for selected slots */}
              {renderFilteredSlots(filteredSlots, tableWidth)}
            </>
          )}
        </div>

        <Table
          stickyHeader
          aria-label="simple table"
          // add 3 sub-slots height below Table, for highlighting the last 3 sub-slots
          sx={{
            pb: `${tableStyle.subRowHeight * 3}px`,
          }}
          ref={tableRef}
        >
          <TimeTableHead
            host={host}
            guests={guests}
            currentDateTimeObj={currentDateTimeObj}
            isMobile={isMobile}
            removeGuest={removeGuest}
            readOnly={readOnly}
            statesFromUrl={statesFromUrl}
          />
          {filteredView ? (
            <FilteredTimeTableBody
              host={host}
              guests={guests}
              handleRowClick={handleRowClick}
              currentDateTimeObj={currentDateTimeObj}
              readOnly={readOnly}
              statesFromUrl={statesFromUrl}
            />
          ) : (
            <DefaultTimeTableBody
              host={host}
              hostTimeArray={hostTimeArray}
              guestsTimeArray={guestsTimeArray}
              isMobile={isMobile}
              handleRowClick={handleRowClick}
              hoverBoxRef={hoverBoxRef}
            />
          )}
        </Table>

        {readOnly && (
          <Button
            size="small"
            variant="outlined"
            style={{ marginTop: 50, marginBottom: 20 }}
            onClick={() => {
              handleOpenInZonejam();
            }}
          >
            Open in Zonejam
          </Button>
        )}
      </TableContainer>
    </>
  );
}
