import {
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
  useResetRecoilState,
} from "recoil";

import {
  groupsState,
  activeGroupState,
  toggleAddGuestPopupState,
  userDetailsState,
  snackPackState,
} from "../state/state";

import { hostState } from "../state/host";
import { guestsState, allGuestsState } from "../state/guest";
import { selectedSlotsState } from "../state/slots";
import { selectedDateState } from "../state/date";

import { uuid, toTitleCase, guestsInfoMatch } from "../lib/utils";
import {
  defaultGroupId,
  hostDefaultState,
  defaultGuest,
  defaultSelectedDate,
} from "../lib/defaults";
import { tableStartTimeState } from "../state/settings";

import { useAppendSnack } from "./useAppendSnack";

export const useSwitchGroup = () => {
  const setActiveGroup = useSetRecoilState(activeGroupState);

  const resetToggleAddGuestPopup = useResetRecoilState(
    toggleAddGuestPopupState
  );
  const resetUserDetails = useResetRecoilState(userDetailsState);
  const resetSnackPack = useResetRecoilState(snackPackState);

  return (groupId) => {
    setActiveGroup(groupId);

    // reset shared global states
    resetToggleAddGuestPopup();
    resetUserDetails();
    resetSnackPack();
  };
};

export const useAddGroup = () => {
  const setGroups = useSetRecoilState(groupsState);
  const setHost = useSetRecoilState(hostState);
  const setGuests = useSetRecoilState(guestsState);
  const setSelectedSlots = useSetRecoilState(selectedSlotsState);
  const setSelectedDate = useSetRecoilState(selectedDateState);
  const [allGuests, setAllGuests] = useRecoilState(allGuestsState);
  const tableStartTime = useRecoilValue(tableStartTimeState);

  const switchGroup = useSwitchGroup();
  const appendSnack = useAppendSnack();

  return (customGroup = null) => {
    // when calls from "Open In Zonejam" of /share URL, customGroup is passed in and we use it's values.
    // Else use the default values.
    let {
      groupName = "new group",
      guests = [{ ...defaultGuest, id: uuid() }],
      selectedSlots = {},
    } = customGroup || {};

    let host;
    if (customGroup === null) {
      host = hostDefaultState;
    } else {
      host = { ...customGroup.host };
      if (host.name === "Me") {
        host.name = "Host";
      }
    }

    // new group id
    const groupId = uuid();

    setGroups((oldGroups) => [...oldGroups, { id: groupId, name: groupName }]);

    setHost((currentHosts) => ({
      ...currentHosts,
      [groupId]: host,
    }));

    if (customGroup) {
      let newGuests = {};

      guests = guests.map((guest) => {
        const matchingGuest = Object.values(allGuests).find((existingGuest) =>
          guestsInfoMatch(existingGuest, guest)
        );

        if (matchingGuest) {
          // if name + timezone matches, use existing guest from allGuest
          return { ...guest, id: matchingGuest.id };
        } else {
          // default GUEST is not considered new guest
          if (!guestsInfoMatch(guest, defaultGuest)) {
            newGuests[guest.id] = guest;
          }

          return guest;
        }
      });

      // add new guests to allGuests
      if (Object.keys(newGuests).length > 0) {
        setAllGuests((prevGuests) => ({
          ...prevGuests,
          ...newGuests,
        }));
      }
    }

    setGuests((currentGuests) => ({
      ...currentGuests,
      [groupId]: guests,
    }));

    setSelectedSlots((currentSlots) => ({
      ...currentSlots,
      [groupId]: selectedSlots,
    }));

    setSelectedDate((currentDates) => ({
      ...currentDates,
      [groupId]: defaultSelectedDate(tableStartTime, host.timezone.timezone),
    }));

    // Switch to the new group
    switchGroup(groupId);

    appendSnack("New group created");
  };
};

export const useDeleteGroup = () => {
  const activeGroup = useRecoilValue(activeGroupState);
  const setGroups = useSetRecoilState(groupsState);
  const setHost = useSetRecoilState(hostState);
  const setGuests = useSetRecoilState(guestsState);
  const setSelectedSlots = useSetRecoilState(selectedSlotsState);
  const setSelectedDate = useSetRecoilState(selectedDateState);

  const switchGroup = useSwitchGroup();

  return (groupId) => {
    // switch to the default group first, if the active group is to be deleted.
    if (groupId === activeGroup) {
      switchGroup(defaultGroupId);
    }

    setGroups((currentGroups) => {
      return currentGroups.filter((group) => group.id !== groupId);
    });

    setHost((currentHosts) => {
      const newHosts = { ...currentHosts };
      delete newHosts[groupId];
      return newHosts;
    });

    setGuests((currentGuests) => {
      const newGuests = { ...currentGuests };
      delete newGuests[groupId];
      return newGuests;
    });

    setSelectedSlots((currentSelectedSlots) => {
      const newSelectedSlots = { ...currentSelectedSlots };
      delete newSelectedSlots[groupId];
      return newSelectedSlots;
    });

    setSelectedDate((currentSelectedDates) => {
      const newSelectedDates = { ...currentSelectedDates };
      delete newSelectedDates[groupId];
      return newSelectedDates;
    });
  };
};

export const useChangeGroupName = () => {
  const setGroups = useSetRecoilState(groupsState);

  return (groupId, newName) => {
    setGroups((currentGroups) =>
      currentGroups.map((group) =>
        group.id === groupId ? { ...group, name: toTitleCase(newName) } : group
      )
    );
  };
};
