export const colors = {
  timeSlot: {
    // transparent color for highlight
    selected: "#CCDBFF80",
    hover: "#E0E9FF60",

    // opaque
    past: "#EFF1F6",
    dummy: "#FFFFFF",
    disabled: "#9E9E9E",
    filteredSlot: "#CCDBFF",
    slotBorders: "#808080",
  },
  text: {
    color: "rgba(0, 0, 0, 0.87)", // from paper
  },
};
