import { DateTime } from "luxon";
import { adjustStartTime } from "../lib/timeUtils";

export const defaultTimezoneValue = {
  city: "Kuala Lumpur",
  country: "Malaysia",
  label: "Malaysia, Kuala Lumpur",
  timezone: "Asia/Kuala_Lumpur",
};

export const feedbackUrl = "https://forms.gle/7XEC7qvQfsTSw91s6";
export const aboutUrl = "https://www.zonejam.com";

export const defaultTimeFormat = "HH:mm";
export const defaultDateFormat = "LLL d";

export const defaultGuestLimit = 9;

export const defaultGroupId = "DEFAULT_GROUP";
export const hostDefaultTimezone = "Asia/Kuala_Lumpur";

export const hostDefaultState = {
  name: "Me",
  timezone: {
    label: "Malaysia, Kuala Lumpur, GMT+8",
    timezone: hostDefaultTimezone,
    city: "Kuala Lumpur",
    country: "Malaysia",
  },
};

const defaultGuestId = "DEFAULT_GUEST";
export const defaultGuest = {
  id: defaultGuestId,
  name: "Guest",
  timezone: {
    label: "India, Delhi, GMT+5.5",
    timezone: "Asia/Kolkata",
    city: "Delhi",
    country: "India",
  },
};

export const defaultSelectedDate = (tableStartTime, hostTimezone) => {
  return adjustStartTime(
    DateTime.local({ zone: hostTimezone }),
    tableStartTime
  ).toISO();
};
