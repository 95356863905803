export const localStorageEffect =
  (key, defaultState) =>
  ({ setSelf, onSet, trigger }) => {
    if (trigger === "get") {
      // make sure current object is initially set to default
      setSelf(defaultState);

      // save the default state to the localstorage in case it's missing
      if (localStorage.getItem(key) === null) {
        localStorage.setItem(key, JSON.stringify(defaultState));
      }
    }

    const savedValue = localStorage.getItem(key);
    if (savedValue != null) {
      // rehydrate from saved local state
      setSelf(JSON.parse(savedValue));
    }

    // save current state
    onSet((newValue, _, isReset) => {
      isReset
        ? localStorage.setItem(key, JSON.stringify(defaultState)) &&
          setSelf(defaultState) // state was reset
        : localStorage.setItem(key, JSON.stringify(newValue));
    });
  };
