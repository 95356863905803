import { useState } from "react";
import { useRecoilValue } from "recoil";

import {
  List,
  ListItemText,
  ListItemIcon,
  IconButton,
  Avatar,
  Divider,
  Tooltip,
} from "@mui/material";

import { styled, useTheme } from "@mui/material/styles";
import { Box } from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import MuiListItemButton from "@mui/material/ListItemButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";

import { activeGroupState, groupsState } from "../state/state";
import { useCheckIfMobile } from "../hooks/useCheckIfMobile";
import {
  useAddGroup,
  useSwitchGroup,
  useDeleteGroup,
  useChangeGroupName,
} from "../hooks/useGroupManagement";

import { defaultGroupId } from "../lib/defaults";
import { toTitleCase, trackEvent } from "../lib/utils";

const drawerWidth = 240;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
}));

const compactMixin = (theme) => ({
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const CompactDrawer = styled(MuiDrawer)(({ theme }) => ({
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...compactMixin(theme),
  "& .MuiDrawer-paper": compactMixin(theme),
}));

const ListItemButton = styled(MuiListItemButton)({
  height: "50px",
  display: "flex",
  alignItems: "center",
});

export const GroupManager = () => {
  const theme = useTheme();

  const activeGroup = useRecoilValue(activeGroupState);
  const groups = useRecoilValue(groupsState);

  const [open, setOpen] = useState(false);
  const [editingGroupId, setEditingGroupId] = useState(null);
  const [editedName, setEditedName] = useState("");

  const addGroup = useAddGroup();
  const switchGroup = useSwitchGroup();
  const deleteGroup = useDeleteGroup();
  const changeGroupName = useChangeGroupName();
  const isMobile = useCheckIfMobile();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setEditingGroupId(null);
  };

  const handleAddGroup = () => {
    addGroup();
    setEditingGroupId(null);
    trackEvent("GROUP_MEETING_ADD", isMobile);
  };

  const handleChangeGroup = (groupId) => {
    switchGroup(groupId);
    setEditingGroupId(null);
    trackEvent("GROUP_MEETING_SWITCH", isMobile);
  };

  const handleDeleteGroup = (groupId) => {
    deleteGroup(groupId);
    setEditingGroupId(null);
    trackEvent("GROUP_MEETING_DELETE", isMobile);
  };

  const handleChangeGroupName = (groupId) => (event) => {
    changeGroupName(groupId, event.target.value);
    trackEvent("GROUP_MEETING_RENAME", isMobile);
  };

  const isGroupActive = (id) => {
    return id === activeGroup;
  };

  const compactDrawer = (
    <CompactDrawer variant="permanent">
      <DrawerHeader>
        <IconButton onClick={handleDrawerOpen}>
          <ChevronRightIcon />
        </IconButton>
      </DrawerHeader>
      <Divider />

      <List>
        {groups.map(({ id, name }) => {
          return (
            <ListItemButton
              key={id}
              selected={isGroupActive(id)}
              onClick={(e) => {
                e.stopPropagation();
                handleChangeGroup(id);
              }}
            >
              <Tooltip title={toTitleCase(name)}>
                <Avatar
                  sx={{
                    bgcolor: "#808080",
                    color: "#fff",
                    width: 30,
                    height: 30,
                    fontSize: "0.875rem",
                  }}
                >
                  {name.charAt(0).toUpperCase()}
                </Avatar>
              </Tooltip>
            </ListItemButton>
          );
        })}
      </List>
      <Divider />

      <List>
        <ListItemButton onClick={handleAddGroup}>
          <ListItemIcon>
            <AddIcon />
          </ListItemIcon>
        </ListItemButton>
      </List>
    </CompactDrawer>
  );

  const expandedDrawer = (
    <MuiDrawer variant="temporary" open={open} onClose={handleDrawerClose}>
      <Box sx={{ width: drawerWidth }}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </DrawerHeader>
        <Divider />

        <List>
          {groups.map(({ id, name }) => {
            const isActiveGroup = isGroupActive(id);
            const isEditingGroup = id === editingGroupId;

            return (
              <ListItemButton
                key={id}
                selected={isActiveGroup}
                onClick={(e) => {
                  e.stopPropagation();

                  if (isActiveGroup) {
                    // skip if already in editing mode.
                    //    if editedName is empty, we disable the save icon.
                    //    clicking on the disabled icon triggers onClick here.
                    if (editingGroupId === id) {
                      return;
                    }

                    // enter editing mode
                    setEditedName(name);
                    setEditingGroupId(id);
                  } else {
                    handleChangeGroup(id);
                  }
                }}
              >
                {isEditingGroup ? (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      style={{ width: "70%" }}
                      value={editedName}
                      onChange={(e) => {
                        setEditedName(e.target.value);
                      }}
                      onFocus={(e) => {
                        // Prevent triggering the group selection
                        e.stopPropagation();
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    />

                    <IconButton
                      disabled={editedName.trim() === ""} // don't allow save if editedName is empty
                      onClick={(e) => {
                        e.stopPropagation();

                        // save edited name
                        handleChangeGroupName(id)({
                          target: { value: editedName },
                        });
                        setEditingGroupId(null);
                      }}
                      edge="end"
                      size="small"
                    >
                      <CheckIcon />
                    </IconButton>

                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        // end editing mode
                        setEditingGroupId(null);
                      }}
                      edge="end"
                      size="small"
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                ) : (
                  <ListItemText
                    primary={toTitleCase(name)}
                    primaryTypographyProps={{
                      style: { textOverflow: "ellipsis", overflow: "hidden" },
                    }}
                  />
                )}

                {id !== defaultGroupId && !isEditingGroup && (
                  <ListItemIcon>
                    <IconButton
                      edge="end"
                      onClick={(event) => {
                        // Prevent triggering the group selection
                        event.stopPropagation();
                        handleDeleteGroup(id);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItemIcon>
                )}
              </ListItemButton>
            );
          })}
        </List>
        <Divider />

        <List>
          <ListItemButton onClick={handleAddGroup}>
            <ListItemIcon>
              <AddIcon />
            </ListItemIcon>
            <ListItemText primary="Add Group" />
          </ListItemButton>
        </List>
      </Box>
    </MuiDrawer>
  );

  return (
    <Box>
      {compactDrawer}
      {expandedDrawer}
    </Box>
  );
};
