import "./App.css";
import { lazy, useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./theme";
import { TimezoneSelectionArea } from "./components/TimezoneSelectionArea";
import { CustomSnackbar } from "./components/shared/CustomSnackbar";
import { useCheckIfMobile } from "./hooks/useCheckIfMobile";

import { currentDateTimeState } from "./state/date";
import { sessionSourceState } from "./state/state";
import { useSetRecoilState } from "recoil";
import { DateTime } from "luxon";
import { useParams } from "react-router-dom";
import {
  decodeState,
  updateHostFromDecoded,
  updateGuestsFromDecoded,
  trackEvent,
} from "./lib/utils";

const LandingHeader = lazy(() => import("./components/LandingHeader"));

export default function SharePage() {
  const [decodedData, setDecodedData] = useState(null);

  const setCurrentDateTime = useSetRecoilState(currentDateTimeState);
  const setSessionSource = useSetRecoilState(sessionSourceState);
  const isMobile = useCheckIfMobile();
  useEffect(() => {
    setSessionSource("Share");
  }, []); // empty dependency array ensures the effect runs only once after the initial render

  useEffect(() => {
    setInterval(() => {
      setCurrentDateTime(DateTime.now().toMillis());
      trackEvent("SESSION_LINK_VIEW", isMobile);
    }, 30000);
  }, [setCurrentDateTime]);

  const { encoded_params } = useParams();

  useEffect(() => {
    decodeState(encoded_params).then((decoded) => {
      const newHost = updateHostFromDecoded(decoded);
      const newGuests = updateGuestsFromDecoded(decoded);

      const newDecoded = { ...decoded };
      newDecoded.host = newHost;
      newDecoded.guests = newGuests;
      setDecodedData(newDecoded);
    });
  }, [encoded_params]);

  return (
    <ThemeProvider theme={theme}>
      <Stack
        direction="column"
        pt={3}
        pl={3}
        spacing={2}
        sx={{
          position: "fixed",
          height: "100%",
          width: "-webkit-fill-available",
          boxSizing: "border-box",
        }}
      >
        <LandingHeader readOnly={true} />
        <TimezoneSelectionArea readOnly={true} statesFromUrl={decodedData} />
      </Stack>
      <CustomSnackbar />
    </ThemeProvider>
  );
}
