import { Fragment } from "react";
import { useRecoilValue } from "recoil";
import { DateTime } from "luxon";
import {
  Divider,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { style } from "../theme";
import { currentDateTimeState } from "../state/date";
import { slotIntervalState } from "../state/settings";
import { getBackgroundColor, getFontWeight } from "../lib/timeUtils";
import { colors } from "../lib/colors";
import { getTopPosition, getRowHeight } from "../lib/utils";
import { selectedDateObjSelector } from "../state/date";

const CustomTableCell = (props) => {
  const {
    time,
    isHost,
    showTimelabel,
    isPastTime,
    isHighlighted,
    showDivider,
    background,
  } = props;

  let backgroundColor;
  if (background) {
    backgroundColor = background;
  } else {
    backgroundColor = getBackgroundColor(time.timeInZone.hour, isPastTime);
  }

  return (
    <TableCell
      align="center"
      key={time.id}
      sx={{
        ...style.tableCell,
        ...(isHost && style.firstTableColumn),
        zIndex: isHost ? 2 : 1,
        boxSizing: "border-box",
        background: backgroundColor,
        ...(isPastTime && { color: colors.timeSlot.disabled }),
      }}
    >
      {showDivider && (
        <Divider sx={{ height: 0, zIndex: 2, mt: "-1px" }}>
          {showTimelabel && (
            <Typography
              sx={{
                mt: "-12px",
                fontWeight: getFontWeight({
                  isSelectedTime: isHighlighted,
                  time: time.timeInZone,
                }),
              }}
            >
              {time.label}
            </Typography>
          )}
        </Divider>
      )}
    </TableCell>
  );
};

const FirstRowTableCell = (props) => {
  const { time, sx } = props;
  return (
    <TableCell align="center" key={time.id} sx={sx}>
      <Typography noWrap variant="caption" display="block">
        {time.timeInZone.toFormat("MMM d")}
      </Typography>
    </TableCell>
  );
};

const FirstTableRow = (props) => {
  const { hostTimeArray, guestsTimeArray, isMobile, isPast } = props;
  const sx = {
    ...style.tableCell,
    zIndex: 1,
  };

  return (
    <TableRow sx={style.firstTableRow}>
      {/* host */}
      <FirstRowTableCell
        time={hostTimeArray[0]}
        key="host-date-cell"
        sx={{
          ...sx,
          ...style.firstTableColumn,
          background: getBackgroundColor(
            hostTimeArray[0].timeInZone.hour,
            isPast
          ),
          zIndex: 2,
        }}
      />

      {/* guests */}
      {guestsTimeArray.map((guestTimes, n) => (
        <FirstRowTableCell
          time={guestTimes[0]}
          key={`${guestTimes[0].id}-date-cell`}
          sx={{
            ...sx,
            background: getBackgroundColor(
              guestTimes[0].timeInZone.hour,
              isPast
            ),
          }}
        />
      ))}

      {/* add guest */}
      <TableCell
        sx={{
          ...style.tableCell,
          backgroundColor: colors.timeSlot.past,
        }}
      />

      {/* Desktop's dummy column */}
      {!isMobile && <TableCell sx={{ width: "100vw" }} />}
    </TableRow>
  );
};

export function DefaultTimeTableBody(props) {
  const {
    host,
    hostTimeArray,
    guestsTimeArray,
    isMobile,
    handleRowClick,
    hoverBoxRef,
  } = props;

  const currentDateTimeObj = DateTime.fromMillis(
    useRecoilValue(currentDateTimeState),
    { zone: host.timezone.timezone }
  );

  const slotInterval = useRecoilValue(slotIntervalState);
  const selectedDateObj = useRecoilValue(selectedDateObjSelector);

  return (
    <TableBody>
      <FirstTableRow
        hostTimeArray={hostTimeArray}
        guestsTimeArray={guestsTimeArray}
        isMobile={isMobile}
        isPast={selectedDateObj < currentDateTimeObj}
      />

      {hostTimeArray.map((hostTime, index) => (
        <Fragment key={hostTime.id}>
          {[0, 15, 30, 45].map((interval, i) => {
            const currentSlot = hostTime.timeInZone.plus({ minutes: interval });
            const isPastTime = currentSlot < currentDateTimeObj;

            const handleMouseOver = (rowIndex) => {
              if (hoverBoxRef.current && !isPastTime) {
                const slotHeight = getRowHeight(slotInterval.minutes / 15);
                const topPosition = getTopPosition(rowIndex);

                hoverBoxRef.current.style.top = `${topPosition}px`;
                hoverBoxRef.current.style.height = `${slotHeight}px`;
                hoverBoxRef.current.style.visibility = "visible";
              }
            };

            const handleMouseOut = () => {
              if (hoverBoxRef.current) {
                hoverBoxRef.current.style.visibility = "hidden";
              }
            };

            const options = {
              isHost: false,
              showTimelabel: i === 0,
              isPastTime: isPastTime,
              showDivider: i === 0,
            };

            return (
              <TableRow
                key={i}
                onClick={() => handleRowClick(hostTime, interval)}
                onMouseEnter={() => handleMouseOver(index * 4 + i)}
                onMouseLeave={() => handleMouseOut()}
                sx={style.tableSubRow}
              >
                {/* host */}
                <CustomTableCell
                  time={hostTime}
                  key={hostTime.id}
                  {...options}
                  isHost={true}
                />

                {/* guests */}
                {guestsTimeArray.map((guestTimes, n) => {
                  const guestTime = guestTimes[index];
                  return (
                    <CustomTableCell
                      time={guestTime}
                      key={guestTime.id}
                      {...options}
                    />
                  );
                })}

                {/* add guest */}
                <CustomTableCell
                  time={hostTime}
                  key={`${hostTime.id}-add-guest-cell`}
                  {...options}
                  showTimelabel={false}
                  background={colors.timeSlot.past}
                />

                {/* Desktop's dummy column */}
                {!isMobile && (
                  <CustomTableCell
                    time={hostTime}
                    key={`${hostTime.id}-last-cell`}
                    {...options}
                    showTimelabel={false}
                    background={colors.timeSlot.dummy}
                  />
                )}
              </TableRow>
            );
          })}
        </Fragment>
      ))}
    </TableBody>
  );
}
