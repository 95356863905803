export const UserModes = {
  EDIT_HOST: "EDIT_HOST",
  EDIT_GUEST: "EDIT_GUEST",
  ADD_GUEST: "ADD_GUEST",
};

export const GuestStatus = {
  MATCH: "MATCH",
  INFO_MATCH: "INFO_MATCH",
  MODIFIED: "MODIFIED",
  NEW: "NEW",
};
