import { Button, DialogActions } from "@mui/material";

export const CustomDialogActions = (props) => {
  const { buttons, sx } = props;

  return (
    <DialogActions sx={sx}>
      {buttons.map((props) => {
        const { variant, onClick, text, dataTestid } = props;
        return (
          <Button
            key={text}
            size="small"
            variant={variant}
            onClick={onClick}
            {...(dataTestid ? { "data-testid": dataTestid } : {})}
          >
            {text}
          </Button>
        );
      })}
    </DialogActions>
  );
};
