import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  typography: {
    fontFamily: ['"Outfit"', "Open Sans"].join(","),
    button: {
      textTransform: "none",
    },
  },
  palette: {
    primary: {
      main: "#151924",
      light: "#575757",
      dark: "#151924",
      contrastText: "#FFF",
    },
    string: {
      main: "#FFF",
      light: "#FFF",
      dark: "#FFF",
      contrastText: "#151924",
    },
  },
});

export const tableStyle = {
  firstRowHeight: 38,
  tableHeadHeight: 130,
  subRowHeight: 20,
};

export const style = {
  tableCell: {
    borderLeft: "1px solid #FFF",
    borderRight: "1px solid #FFF",
    width: { xs: 117, md: 167 },
    maxWidth: { xs: 117, md: 167 },
    minWidth: { xs: 117, md: 167 },
  },
  tableHeader: {
    borderRadius: "6px 6px 0px 0px",
    background: "#151924",
    color: "#FFF",
    py: 0.5,
    cursor: "pointer",
    zIndex: 6,
  },
  firstTableColumn: {
    position: "-webkit-sticky",
    position: "sticky",
    left: 0,
  },
  firstTableRow: {
    "& .MuiTableCell-body": {
      px: 0,
      pt: 0,
      pb: 1,
      borderBottom: "none",
      verticalAlign: "super",
      boxSizing: "border-box",
      height: tableStyle.firstRowHeight,
    },
  },
  tableHeadRow: {
    "& .MuiTableCell-head": {
      height: tableStyle.tableHeadHeight,
      boxSizing: "border-box",
    },
  },
  tableSubRow: {
    "& .MuiTableCell-body": {
      p: 0,
      borderBottom: "none",
      height: tableStyle.subRowHeight,
      cursor: "pointer",
      verticalAlign: "top",
    },
  },
  filteredViewColumn: {
    px: 0,
    fontSize: "12px",
  },
  noScrollbar: {
    msOverflowStyle: "none" /* for Internet Explorer, Edge */,
    scrollbarWidth: "none" /* for Firefox */,

    "&::-webkit-scrollbar": {
      display: "none" /* for Chrome, Safari, and Opera */,
    },
  },
};
