import { atom, selector, DefaultValue } from "recoil";
import { localStorageEffect } from "./effects";
import { getSortedSlots } from "../lib/timeUtils";
import { activeGroupState } from "./state";
import { defaultGroupId } from "../lib/defaults";

const defaultSelectedSlots = {
  [defaultGroupId]: {},
};
export const selectedSlotsState = atom({
  key: "selectedSlots",
  default: defaultSelectedSlots,
  effects: [localStorageEffect("selectedSlots", defaultSelectedSlots)],
});

export const selectedSlotsByGroupIdSelector = selector({
  key: "selectedSlotsSelector",
  get: ({ get }) => {
    const groupId = get(activeGroupState);
    const selectedSlots = get(selectedSlotsState);
    return selectedSlots[groupId];
  },
  set: ({ set, get }, newValue) => {
    const groupId = get(activeGroupState);
    const slots = get(selectedSlotsState);

    if (newValue instanceof DefaultValue) {
      // If it's a reset action, reset to default
      set(selectedSlotsState, { ...slots, [groupId]: {} });
    } else {
      // Otherwise update the state
      set(selectedSlotsState, { ...slots, [groupId]: newValue });
    }
  },
});

export const sortedSlotsSelector = selector({
  key: "sortedSlots",
  get: ({ get }) => {
    const selectedSlots = get(selectedSlotsByGroupIdSelector);
    return getSortedSlots(selectedSlots);
  },
});
