import React from "react";
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { RecoilRoot } from "recoil";

Sentry.init({
  dsn: "https://25dc7d77a7df4a209bc9e204ad7f285f@o1170231.ingest.sentry.io/4504767142625280",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const version = localStorage.getItem("version");
if (version === null) {
  const savedGuests = localStorage.getItem("guests");

  if (savedGuests !== null) {
    const parsedGuests = JSON.parse(savedGuests);

    // user with old data format: clear local storage
    if (Array.isArray(parsedGuests)) {
      localStorage.clear();

      Sentry.captureEvent({
        message: "Cleared local storage due to old data format",
        level: "warning",
        extra: { guests: savedGuests },
      });

      alert(
        "We have updated the way we store information on our site. Unfortunately, this means we had to clear your previous data. We apologize for any inconvenience."
      );
    }
  }
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RecoilRoot>
      <App />
    </RecoilRoot>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
