import { v1 } from "uuid";
import { DateTime } from "luxon";
import { isSlotsEmpty, findTimezone, getSortedSlots } from "./timeUtils";
import { tableStyle } from "../theme";
import JsonUrl from "json-url";
import "core-js";

// generates a "sortable" uuid
// see https://github.com/uuidjs/uuid/issues/75#issuecomment-25936084
export const uuid = () => {
  return v1().replace(/^(.{8})-(.{4})-(.{4})/, "$3-$2-$1");
};

const formatGuestTime = (time, guest, timeFormat) => {
  // Fri, Mar 3 at 5 AM (for You in London)
  // Fri, Mar 3 at 2:30 AM (for Meghan in Mumbai)
  const timeStr = time.toFormat(timeFormat);
  return `${time.toFormat("ccc, LLL d")} at ${timeStr} (${guest.name} in ${
    guest.timezone.city
  })`;
};

// [Option 1]
// Tue, Feb 21 at 9 AM (for Harry in Seattle)
// Tue, Feb 21 at 5 PM (for Becky in London)
// Wed, Feb 22 at 1 AM (for Siew Keong in Kuala Lumpur)
// Wed, Feb 22 at 1 AM (for Ahmad in Johor)

// host is a js object with the keys timezone
// guests is an array of js objects with the keys timezone
export const generateShareText = (
  selectedSlots,
  host,
  guests,
  timeFormat = "h:mm a",
  options = { asString: true }
) => {
  if (isSlotsEmpty(selectedSlots)) return;

  const slotArray = Object.keys(selectedSlots).sort();
  let cnt = 1;

  const retVal = slotArray.map((t) => {
    const time = DateTime.fromISO(t, { zone: host.timezone.timezone });

    const text = [];
    if (slotArray.length > 1) {
      const formattedString = `[Option ${cnt++}]`;
      if (options.asString) text.push(formattedString);
      else text.push({ time, formattedString });
    }

    const allGuests = [host, ...guests];
    allGuests.forEach(function (g) {
      const formattedString = formatGuestTime(
        time.setZone(g.timezone.timezone),
        g,
        timeFormat
      );
      if (options.asString) text.push(formattedString);
      else text.push({ time, formattedString });
    });

    return options.asString ? text.join("\n") : text;
  });

  return options.asString ? retVal.join("\n\n") : retVal;
};

// note that we pass an object
//   e.g. generateICal({start: xxx, end: yyy, summary: zzz ...etc})
// start and end are luxon date objects
// summary is a string that sets the event title
// description is a string that sets the meeting notes
//   this likely would be the result of generateShareText
// location is a string that sets the location of the meeting
export const generateICal = ({
  start,
  end,
  summary,
  description,
  location,
}) => {
  start ||= DateTime.now();
  end ||= start.plus({ hours: 1 });
  summary ||= "My Meeting";

  const ical = new Blob(
    [
      `
BEGIN:VCALENDAR
VERSION:2.0
PRODID:-//Hivekind//NONSGML Zonejam//EN
BEGIN:VEVENT
UID:${uuid()}@zonejam.com
DTSTAMP:${convertToIcsFormat(start)}
DTSTART:${convertToIcsFormat(start)}
DTEND:${convertToIcsFormat(end)}
SUMMARY:${summary}
DESCRIPTION:${replaceNewLines(description)}
LOCATION:${location}
CLASS:PRIVATE
END:VEVENT
END:VCALENDAR
`.trim(),
    ],
    { type: "text/calendar" }
  );
  return URL.createObjectURL(ical);
};

const convertToIcsFormat = (date) =>
  `${date
    .setZone("UTC")
    .toISO()
    .replace(/-|:|\.(.*)/g, "")}Z`;

const replaceNewLines = (string) => string.replace(/(?:\r\n|\r|\n)/g, "\\n");

export const trackEvent = (name, isMobile) => {
  window.gtag("event", name, {
    app_name: "zonejam",
    source: isMobile ? "mobile" : "desktop",
  });
};

export const clearLocalStorage = () => {
  localStorage.clear();
  window.location.reload();
};

export const openUrlInNewTab = (url) => {
  window.open(url, "_blank", "noreferrer");
};

export async function encodeState(groupName, host, guests, selectedSlots) {
  const codec = JsonUrl("lzstring");

  const newHost = structuredClone(host);
  delete newHost.timezone.timezone;
  delete newHost.timezone.label;

  const newGuests = structuredClone(guests);
  newGuests.forEach((guest) => {
    delete guest.id;
    delete guest.timezone.timezone;
    delete guest.timezone.label;
  });

  const response = await codec.compress({
    groupName: groupName,
    host: newHost,
    guests: newGuests,
    selectedSlots: selectedSlots,
  });
  return response;
}

export async function decodeState(encodedState) {
  const codec = JsonUrl("lzstring");
  const response = await codec.decompress(encodedState);
  return response;
}

export function updateHostFromDecoded(decoded) {
  const host = { ...decoded.host };
  const tz = findTimezone(host.timezone.city, host.timezone.country);

  if (tz) {
    host.timezone.timezone = tz.timezone;
    host.timezone.label = tz.label;
  }
  return host;
}

export function updateGuestsFromDecoded(decoded) {
  const guests = [...decoded.guests];
  for (const guest of guests) {
    const tz = findTimezone(guest.timezone.city, guest.timezone.country);

    if (tz) {
      guest.timezone.timezone = tz.timezone;
      guest.timezone.label = tz.label;
    }
    guest.id = uuid();
  }
  return guests;
}

export function getSortedSlotsFromDecodedData(decoded) {
  return getSortedSlots(decoded.selectedSlots);
}

export function toTitleCase(str) {
  let strArray = [];

  for (const word of str.toLowerCase().split(" ")) {
    strArray.push(word.charAt(0).toUpperCase() + word.slice(1));
  }
  return strArray.join(" ");
}

export const guestsInfoMatch = (guest1, guest2) => {
  return (
    guest1.name === guest2.name &&
    guest1.timezone.country === guest2.timezone.country &&
    guest1.timezone.city === guest2.timezone.city &&
    guest1.timezone.timezone === guest2.timezone.timezone
  );
};

// TimeTable: get the start Y-position for hover / selected slots highlight box
export const getTopPosition = (startRowIndex) => {
  const { firstRowHeight, tableHeadHeight, subRowHeight } = tableStyle;

  return tableHeadHeight + firstRowHeight + startRowIndex * subRowHeight;
};

// TimeTable: get hover / selected slots highlight box's height
export const getRowHeight = (rowCount) => {
  return rowCount * tableStyle.subRowHeight;
};

export const isGuestPresentInAnotherGroup = (guest_id, groups, activeGroup) => {
  const filteredGroups = Object.entries(groups).filter(
    (group) => group[0] !== activeGroup
  );
  const otherGroupGuests = filteredGroups.map((group) => group[1]).flat();
  const otherGroupGuestsIds = otherGroupGuests.map((guest) => guest.id);
  return otherGroupGuestsIds.includes(guest_id);
};
