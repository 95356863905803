import { lazy, Suspense } from "react";
import { useSetRecoilState, useRecoilValue } from "recoil";
import {
  Box,
  IconButton,
  Stack,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { style } from "../theme";
import { trackEvent } from "../lib/utils";
import { userDetailsState, toggleAddGuestPopupState } from "../state/state";
import { timeFormatState } from "../state/settings";
import { colors } from "../lib/colors";
import { formattedDateString } from "../lib/timeUtils";
import { UserModes } from "../lib/constants";

const AddGuest = lazy(() => import("./AddGuest"));
const CloseIcon = lazy(() => import("@mui/icons-material/Close"));

export function TimeTableHead(props) {
  let {
    host,
    guests,
    currentDateTimeObj,
    isMobile,
    removeGuest,
    readOnly = false,
    statesFromUrl = null,
  } = props;
  const setUserDetails = useSetRecoilState(userDetailsState);
  const setOpen = useSetRecoilState(toggleAddGuestPopupState);
  const timeFormat = useRecoilValue(timeFormatState);

  const showEditHost = (host) => {
    setUserDetails({ ...host, mode: UserModes.EDIT_HOST });
    setOpen(true);
  };

  const showEditGuest = (guest) => {
    setUserDetails({ ...guest, oldId: guest.id, mode: UserModes.EDIT_GUEST });
    setOpen(true);
  };

  // support /share URL in read only mode
  if (readOnly && statesFromUrl !== null) {
    host = statesFromUrl.host;
    guests = statesFromUrl.guests;
  }

  return (
    <TableHead>
      <TableRow sx={style.tableHeadRow}>
        <TableCell
          sx={{
            ...style.tableCell,
            ...style.tableHeader,
            ...style.firstTableColumn,
            zIndex: 7,
            cursor: readOnly ? "auto" : "pointer",
          }}
          {...(readOnly ? {} : { onClick: () => showEditHost(host) })}
        >
          <Typography pb={2} noWrap>
            {host.name}
          </Typography>
          <Typography noWrap variant="caption" display="block">
            {host.timezone.city}
            <br />
            {host.timezone.country}
            <br />
            {formattedDateString({
              date: currentDateTimeObj,
              timeFormat,
              timezone: host.timezone.timezone,
              withOffset: isMobile ? false : true,
            })}
          </Typography>
        </TableCell>
        {guests.map((guest) => (
          <TableCell
            key={guest.id}
            sx={{
              ...style.tableCell,
              ...style.tableHeader,
              cursor: readOnly ? "auto" : "pointer",
            }}
            data-testid={`GUEST_PROFILE_VIEW ${guest.name}`}
            {...(readOnly
              ? {}
              : {
                  onClick: () => {
                    showEditGuest(guest);
                    trackEvent("GUEST_PROFILE_VIEW", isMobile);
                  },
                })}
          >
            <Stack pb={2} direction="row" justifyContent="space-between">
              <Box sx={{ width: "90%", pr: 4 }}>
                <Typography
                  noWrap
                  data-testid={`GUEST_PROFILE_NAME ${guest.name}`}
                >
                  {guest.name}
                </Typography>
              </Box>
              {!readOnly && (
                <IconButton
                  data-testid={`GUEST_PROFILE_REMOVE ${guest.name}`}
                  color="string"
                  size="string"
                  edge="end"
                  aria-label="remove attendee"
                  sx={{
                    p: 0,
                    position: "absolute",
                    right: 16,
                    top: 6,
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    removeGuest(guest.id);
                    trackEvent("GUEST_PROFILE_REMOVE", isMobile);
                  }}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              )}
            </Stack>
            <Typography
              noWrap
              variant="caption"
              display="block"
              data-testid={`GUEST_PROFILE_LOCATION ${guest.name}`}
            >
              {guest.timezone.city}
              <br />
              {guest.timezone.country}
              <br />
              {formattedDateString({
                date: currentDateTimeObj,
                timeFormat,
                timezone: guest.timezone.timezone,
                withOffset: isMobile ? false : true,
              })}
            </Typography>
          </TableCell>
        ))}
        {!readOnly && (
          <TableCell
            align="center"
            sx={{
              ...style.tableCell,
              borderRadius: "6px 6px 0px 0px",
              backgroundColor: colors.timeSlot.past,
              zIndex: 6,
            }}
          >
            <Suspense fallback={<div>Loading...</div>}>
              <AddGuest />
            </Suspense>
          </TableCell>
        )}
        {!isMobile && (
          <TableCell
            sx={{
              width: "100vw",
              backgroundColor: "#fff",
              zIndex: 6,
            }}
          ></TableCell>
        )}
      </TableRow>
    </TableHead>
  );
}
