import { atom, selector } from "recoil";
import { localStorageEffect } from "./effects";
import { defaultGroupId } from "../lib/defaults";

// key-value pairs of: groupId: groupName
const defaultGroups = [{ id: defaultGroupId, name: "default" }];

export const groupsState = atom({
  key: "groups",
  default: defaultGroups,
  effects: [localStorageEffect("groups", defaultGroups)],
});

export const activeGroupState = atom({
  key: "activeGroup",
  default: defaultGroupId,
  effects: [localStorageEffect("activeGroup", defaultGroupId)],
});

export const activeGroupNameSelector = selector({
  key: "activeGroupName",
  get: ({ get }) => {
    const groups = get(groupsState);
    const activeGroup = get(activeGroupState);
    const group = groups.find((group) => group.id === activeGroup);
    return group ? group.name : undefined;
  },
});

// For now, we will keep each of the below states as a single global state.
// eg: toggleAddGuestPopupState will be shared among groups and we won't keep it per group id.
// So when we switch group, we need to reset these states:
//
//    const resetToggleAddGuestPopup = useResetRecoilState(toggleAddGuestPopupState);
//    const resetUserDetails = useResetRecoilState(userDetailsState);
//    const resetSnackPack = useResetRecoilState(snackPackState);
//
//    resetToggleAddGuestPopup();
//    resetUserDetails();
//    resetSnackPack();
export const toggleAddGuestPopupState = atom({
  key: "toggleAddGuestPopup",
  default: false,
  effects: [localStorageEffect("open", false)],
});

export const userDetailsState = atom({
  key: "userDetails",
  default: null,
  effects: [localStorageEffect("userDetails", null)],
});

export const snackPackState = atom({
  key: "snackPack",
  default: [],
});

export const sessionSourceState = atom({
  key: "sessionSource",
  default: null,
  effects: [localStorageEffect("sessionSource", null)],
});
