import { useMediaQuery } from "@mui/material";

// This media query is used internally in Datepicker's code, to decide whether
//  showing DesktopDatePicker or MobileDatePicker
// https://github.com/mui/mui-x/blob/master/packages/x-date-pickers/src/internals/utils/utils.ts#L50
export const DESKTOP_MODE_MEDIA_QUERY = "@media (pointer: fine)";

export const useCheckIfMobile = (query) => {
  return !useMediaQuery(query || DESKTOP_MODE_MEDIA_QUERY);
};
