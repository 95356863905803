import { atom } from "recoil";
import { localStorageEffect } from "./effects";
import { defaultTimeFormat } from "../lib/defaults";

export const timeFormatState = atom({
  key: "timeFormat",
  default: defaultTimeFormat,
  effects: [localStorageEffect("timeFormat", defaultTimeFormat)],
});

const defaultSlotIntervalState = { minutes: 60 };
export const slotIntervalState = atom({
  key: "slotInterval",
  default: defaultSlotIntervalState,
  effects: [localStorageEffect("slotInterval", defaultSlotIntervalState)],
});

export const filteredViewEnabledState = atom({
  key: "filteredViewEnabled",
  default: false,
  effects: [localStorageEffect("filteredViewEnabled", false)],
});

export const tableStartTimeState = atom({
  key: "tableStartTime",
  default: 8,
  effects: [localStorageEffect("tableStartTime", 8)],
});
