import * as React from "react";
import {
  IconButton,
  Stack,
  Typography,
  Grid,
  TextField,
  Button,
} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { DateTime } from "luxon";
import {
  selectedDateByGroupIdSelector,
  selectedDateObjSelector,
} from "../state/date";
import { hostByGroupIdSelector } from "../state/host";
import { adjustStartTime } from "../lib/timeUtils";
import { theme } from "../theme";
import { useCheckIfMobile } from "../hooks/useCheckIfMobile";
import { CustomDialogActions } from "./CustomDialogActions";
import { CustomDialogTitle } from "./CustomDialogTitle";
import { ShareButton } from "./ShareButton";
import { tableStartTimeState } from "../state/settings";

const MobileToolbar = ({ ownerState }) => {
  const onCancel = ownerState.onCancel;

  return (
    <Grid
      container
      sx={{
        gridColumnStart: 2,
        gridColumEnd: 4,
        gridRowStart: 1,
        gridRowEnd: "auto",
        justifyContent: "space-between",
      }}
    >
      <CustomDialogTitle
        title="Select date"
        id="select-date-title"
        onClose={onCancel}
      />
    </Grid>
  );
};

const DesktopTextField = ({ InputProps, ...params }) => {
  InputProps.disableUnderline = true;
  delete InputProps["endAdornment"];
  params.InputProps = InputProps;

  return <TextField {...params} />;
};

const MobileTextField = ({ onClick, value }) => {
  return (
    <Typography
      noWrap
      display="block"
      onClick={onClick}
      sx={{
        display: "flex",
        alignItems: "center",
        fontFamily: theme.typography.fontFamily,
      }}
    >
      {value}
    </Typography>
  );
};

const MobileActionBar = ({ onAccept }) => {
  const buttons = [
    {
      text: "Save",
      onClick: onAccept,
      variant: "contained",
    },
  ];

  return (
    <Grid
      container
      px={3}
      pb={2}
      sx={{
        gridColumnStart: 2,
        gridColumnEnd: "auto",
        gridRowStart: 3,
        gridRowEnd: "auto",
        justifyContent: "flex-end",
      }}
    >
      <CustomDialogActions sx={{ px: 0, py: 0 }} buttons={buttons} />
    </Grid>
  );
};

export function MuiDatePicker() {
  const host = useRecoilValue(hostByGroupIdSelector);
  const selectedDateObj = useRecoilValue(selectedDateObjSelector);
  const tableStartTime = useRecoilValue(tableStartTimeState);

  const setSelectedDate = useSetRecoilState(selectedDateByGroupIdSelector);
  const [open, setOpen] = React.useState(false);

  const adjustTimeAndSave = (date) => {
    const startTime = adjustStartTime(date, tableStartTime);
    setSelectedDate(startTime.toISO());
  };

  // move to next or previous day
  const moveByDay = (dayOffset) => {
    const date = selectedDateObj.plus({ days: dayOffset });
    adjustTimeAndSave(date);
  };

  const isMobile = useCheckIfMobile();

  const datePickerProps = {
    open: open,
    onOpen: () => setOpen(true),
    onClose: () => setOpen(false),
    format: "EEE, MMM d, yyyy",
    disableMaskedInput: true,
    value: selectedDateObj,
    onChange: (date) => adjustTimeAndSave(date),
  };

  if (isMobile) {
    datePickerProps.localeText = { okButtonLabel: "Save" };

    datePickerProps.slots = {
      textField: MobileTextField,
      toolbar: MobileToolbar,
      actionBar: MobileActionBar,
    };

    datePickerProps.slotProps = {
      textField: {
        onClick: () => setOpen(true),
      },
      actionBar: { isMobile: true },
    };
  } else {
    datePickerProps.slots = {
      textField: DesktopTextField,
    };

    datePickerProps.slotProps = {
      textField: {
        onClick: () => setOpen(true),
        readOnly: true,
        variant: "standard",
        sx: {
          width: 140,
          justifyContent: "center",
          "& input": {
            textAlign: "center",
          },
        },
      },
      actionBar: { isMobile: false },
    };
  }

  const setToday = () => {
    const newDate = DateTime.local({ zone: host.timezone.timezone });
    adjustTimeAndSave(newDate);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <Stack direction="row" pb={2} pt={isMobile ? 0 : 1}>
        <Stack direction="row">
          <IconButton
            aria-label="previous date"
            size="small"
            onClick={() => moveByDay(-1)}
            sx={{ pl: 0 }}
          >
            <ChevronLeftIcon />
          </IconButton>

          <DatePicker {...datePickerProps} />

          <IconButton
            aria-label="next date"
            size="small"
            onClick={() => moveByDay(1)}
          >
            <ChevronRightIcon />
          </IconButton>
        </Stack>
        <Button
          key="today"
          size="small"
          variant="outlined"
          onClick={setToday}
          sx={{
            ml: 1.5,
            mr: 3,
            my: isMobile ? 1 : 0,
          }}
        >
          Today
        </Button>
        <ShareButton />
      </Stack>
    </LocalizationProvider>
  );
}
