import { DateTime } from "luxon";
import { uuid } from "./utils";
import { defaultDateFormat, defaultTimeFormat } from "./defaults";
import localesLut from "./localesLut";
import timezoneOptions from "./timezoneOptions";

const futureColorMap = {
  "#CCFFF0": [9, 10, 11, 12, 13, 14, 15, 16, 17], //9AM-6PM
  "#FFF0CC": [6, 7, 8, 18, 19, 20, 21], //6AM-9AM, 6PM-10PM
  "#FCCFDC": [22, 23, 0, 1, 2, 3, 4, 5], //10PM-6AM
};
const pastColorMap = {
  "#A6CDC2": [9, 10, 11, 12, 13, 14, 15, 16, 17], //9AM-6PM
  "#CBC0A4": [6, 7, 8, 18, 19, 20, 21], //6AM-9AM, 6PM-10PM
  "#D1AFB9": [22, 23, 0, 1, 2, 3, 4, 5], //10PM-6AM
};

export function getBackgroundColor(hour, isPast = false) {
  let color = "";
  const colorMap = isPast ? pastColorMap : futureColorMap;

  Object.keys(colorMap).forEach(function (key) {
    const timeArray = colorMap[key];
    if (timeArray.includes(hour)) {
      color = key;
    }
  });

  return color;
}

// `startDate` is a luxon object in specific timezone
export function generateTimeArray({
  startDate,
  currentDateTime,
  interval = { minutes: 60 },
  timeFormat = defaultTimeFormat,
}) {
  const rowCount = (24 * 60) / interval.minutes;
  const timeArray = [];
  let timeSlot = startDate;

  // can't use the logic of 1 day here, as during enter / exit daylight saving,
  //  hours/day will be decrease / increase by 1 hour.
  // so we hardcode the expected rows count
  for (let i = 0; i < rowCount; i++) {
    timeArray.push({
      id: uuid(),
      label: isMidnight(timeSlot)
        ? timeSlot.toFormat(defaultDateFormat)
        : timeSlot.toFormat(timeFormat),
      timeInZone: timeSlot,
      isPastTime: timeSlot < currentDateTime,
    });
    timeSlot = timeSlot.plus(interval);
  }

  return timeArray;
}

export const isMidnight = (time) =>
  time.toFormat("HH") === "00" && time.toFormat("mm") === "00";

// `date` is a Luxon object in specific timezone
export const adjustStartTime = (date, tableStartTime) => {
  const hour = () => {
    if (isNaN(tableStartTime)) {
      return date.hour;
    } else {
      return tableStartTime === 12 ? 0 : tableStartTime;
    }
  };
  return date.set({
    hour: hour(),
    minute: 0,
    second: 0,
    millisecond: 0,
  });
};

export const getSlotsCount = (selectedSlots) => {
  return Object.keys(selectedSlots).length;
};

export const isSlotsEmpty = (selectedSlots) => {
  const count = getSlotsCount(selectedSlots);
  return count === 0 ? true : false;
};

export const isSlotsSingle = (selectedSlots) => {
  const count = getSlotsCount(selectedSlots);
  return count === 1 ? true : false;
};

export const removeSlot = ({ selectedSlots, time }) => {
  const newSlots = { ...selectedSlots };
  delete newSlots[time.toISO()];
  return newSlots;
};

export const addSlot = ({
  selectedSlots,
  time,
  slotInterval = { minutes: 60 },
}) => {
  const newSlots = { ...selectedSlots };

  const endTime = time.plus(slotInterval);
  newSlots[time.toISO()] = endTime.toISO();

  return newSlots;
};

export const isSelectedSlot = ({ selectedSlots, time }) => {
  const startTime = time.toISO();
  const selectedSlot = selectedSlots[startTime];

  if (!selectedSlot) {
    return {
      isSelected: false,
      slot: null,
    };
  }

  return {
    isSelected: true,
    slot: [startTime, selectedSlot],
  };
};

// we want to highlight the time display in TimeTable when:
// 1) `time` is either start / end time of selected slot
// 2) `time` is midnight (crosses day)
//
// isSelectedTime (bool): is true when `time` is either start / end time of selected slot
// time: Luxon date obj
export const getFontWeight = ({ isSelectedTime, time }) => {
  return isSelectedTime || isMidnight(time) ? "500" : "400";
};

export const generateDateString = (
  dateString,
  timezone,
  dateFormat = defaultDateFormat
) => {
  return DateTime.fromISO(dateString, { zone: timezone }).toFormat(dateFormat);
};

export const formattedDateString = ({
  date,
  timeFormat = defaultTimeFormat,
  dateFormat = defaultDateFormat,
  timezone,
  withOffset = false,
  withTime = true,
  withYear = false,
} = {}) => {
  const dateInZone = date.setZone(timezone);

  let format = dateFormat;
  if (withTime) format = `${timeFormat}, ${format}`;
  if (withYear) format = `${format}, y`;

  const locale =
    localesLut[timezone]?.length > 0 ? localesLut[timezone][0] : "en-US";

  return `${dateInZone.toFormat(format)} ${
    withOffset ? dateInZone.setLocale(locale).offsetNameShort : ""
  }`.trim();
};

export function findTimezone(city, country) {
  const entry = timezoneOptions.find(
    (option) => option.city === city && option.country === country
  );
  return entry;
}

export function getSortedSlots(selectedSlots) {
  return Object.entries(selectedSlots).sort((a, b) => {
    if (a[0] === b[0]) return 0;
    return a[0] < b[0] ? -1 : 1;
  });
}
