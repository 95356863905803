import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import MainApp from "./MainApp";
import SharePage from "./SharePage";

export default function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MainApp />} />
        <Route path="/share/:encoded_params" element={<SharePage />} />
      </Routes>
    </Router>
  );
}
