import { useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { Button, DialogContent, Typography, Tooltip, Box } from "@mui/material";
import {
  generateShareText,
  generateICal,
  trackEvent,
  encodeState,
} from "../lib/utils";
import { isSlotsEmpty, isSlotsSingle } from "../lib/timeUtils";
import { guestsByGroupIdSelector } from "../state/guest";
import { activeGroupNameSelector } from "../state/state";
import { currentDateTimeState } from "../state/date";
import { hostByGroupIdSelector } from "../state/host";
import { selectedSlotsByGroupIdSelector } from "../state/slots";
import { DateTime } from "luxon";
import { useCheckIfMobile } from "../hooks/useCheckIfMobile";
import { CustomDialog } from "./CustomDialog";
import { useAppendSnack } from "../hooks/useAppendSnack";
import { timeFormatState } from "../state/settings";
import { colors } from "../lib/colors";

export const ShareButton = () => {
  const host = useRecoilValue(hostByGroupIdSelector);
  const guests = useRecoilValue(guestsByGroupIdSelector);
  const selectedSlots = useRecoilValue(selectedSlotsByGroupIdSelector);
  const activeGroupName = useRecoilValue(activeGroupNameSelector);
  const timeFormat = useRecoilValue(timeFormatState);

  const appendSnack = useAppendSnack();

  const currentDateTimeObj = DateTime.fromMillis(
    useRecoilValue(currentDateTimeState),
    { zone: host.timezone.timezone }
  );

  const [open, setOpen] = useState(false);
  const toggleDrawer = (open) => {
    setOpen(open);
  };

  const isMobile = useCheckIfMobile();

  const canShare = navigator.canShare ? true : false;

  const textToCopy = generateShareText(selectedSlots, host, guests, timeFormat);
  const textWithZonejam = `${textToCopy}

Generated by zonejam.com
  `;

  const iCalHandler = () => {
    const e = document.createElement("a");
    e.href = generateICal({
      start: DateTime.fromISO(Object.keys(selectedSlots)[0]),
      end: DateTime.fromISO(Object.values(selectedSlots)[0]),
      description: textWithZonejam,
      location: host.timezone.city,
    });
    e.download = "options.ics";
    document.body.appendChild(e);
    e.click();

    toggleDrawer(false);
    trackEvent("SHARE_SEND_ICS", isMobile);
  };

  const shareHandler = async () => {
    if (canShare) {
      try {
        await navigator.share({ text: textWithZonejam });
        // maybe snackbar here?
      } catch (err) {
        console.error(err);
        // failure message
      }
    } else {
      navigator.clipboard.writeText(textWithZonejam);
      appendSnack("Copied to clipboard");
    }

    toggleDrawer(false);
    trackEvent("SHARE_COPY_TEXT", isMobile);
  };

  const copyShareURLHandler = () => {
    encodeState(activeGroupName, host, guests, selectedSlots).then(
      (encoded) => {
        const shareURL = `${window.location.origin}/share/${encoded}`;
        trackEvent("SHARE_COPY_TEXT", isMobile);
        navigator.clipboard.writeText(shareURL);
      }
    );
  };

  const closeHandler = () => toggleDrawer(false);

  const titleProps = {
    title: "Share",
    id: "share-title",
    onClose: closeHandler,
  };

  const buttons = [];

  buttons.push({
    text: "Copy Share URL",
    onClick: copyShareURLHandler,
    variant: "outlined",
  });

  if (isSlotsSingle(selectedSlots)) {
    buttons.push({
      text: "Add to Calendar",
      onClick: iCalHandler,
      variant: "outlined",
    });
  }

  buttons.push({
    text: canShare ? "Share" : "Copy",
    onClick: shareHandler,
    variant: "contained",
  });

  const actionProps = {
    sx: { px: 3, py: 2 },
    buttons: buttons,
  };

  const options = generateShareText(selectedSlots, host, guests, timeFormat, {
    asString: false,
  });

  return (
    <>
      <Button
        key="share"
        size="small"
        variant="outlined"
        onClick={() => {
          toggleDrawer(true);
          trackEvent("SHARE_DIALOG_VIEW", isMobile);
        }}
        disabled={isSlotsEmpty(selectedSlots)}
        sx={{
          mr: 2,
          my: isMobile ? 1 : 0,
        }}
      >
        Share
      </Button>
      <CustomDialog
        open={open}
        onClose={closeHandler}
        aria-labelledby="share-content"
        aria-describedby="share-timezones"
        titleProps={titleProps}
        actionProps={actionProps}
      >
        <DialogContent dividers sx={{ maxHeight: "34vh" }}>
          <Typography variant="p" sx={{ pb: 2, whiteSpace: "pre-wrap" }}>
            {options?.map((lines, index) => {
              return (
                <div key={index} style={{ marginBottom: "1em" }}>
                  {lines.map((line) => {
                    return (
                      <TooltipWrapper
                        title="This slot selection has already passed."
                        isPast={line.time < currentDateTimeObj}
                        key={line.formattedString}
                      >
                        <>
                          {line.formattedString}
                          <br />
                        </>
                      </TooltipWrapper>
                    );
                  })}
                </div>
              );
            })}
          </Typography>
        </DialogContent>
      </CustomDialog>
    </>
  );
};

function TooltipWrapper(props) {
  const { isPast, title, children } = props;
  if (isPast)
    return (
      <Tooltip title={title} followCursor>
        <Box sx={{ fontStyle: "italic", color: colors.text.color }}>
          {children}
        </Box>
      </Tooltip>
    );
  else return children;
}
