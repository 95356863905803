import "./App.css";
import { lazy, useEffect } from "react";
import { Stack, Box } from "@mui/material";
// import screenfull from 'screenfull';
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./theme";
import { TimezoneSelectionArea } from "./components/TimezoneSelectionArea";
import { CustomSnackbar } from "./components/shared/CustomSnackbar";
import { MuiDatePicker } from "./components/MuiDatePicker";
import { GroupManager } from "./components/GroupManager";

import { DateTime } from "luxon";
import { useSetRecoilState } from "recoil";
import { currentDateTimeState } from "./state/date";

const LandingHeader = lazy(() => import("./components/LandingHeader"));

export default function MainApp() {
  const setCurrentDateTime = useSetRecoilState(currentDateTimeState);

  useEffect(() => {
    const version = localStorage.getItem("version");

    if (version === null) {
      localStorage.setItem("version", "1");
    }
  }, []); // empty dependency array ensures this effect runs only once, when the component mounts

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDateTime(DateTime.now().toMillis());
    }, 30000);

    // cleanup function
    return () => {
      clearInterval(intervalId);
    };
  }, [setCurrentDateTime]);

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: "flex",
          position: "fixed",
          height: "100%",
          width: "-webkit-fill-available",
          boxSizing: "border-box",
        }}
      >
        <GroupManager />
        <Stack
          direction="column"
          pt={1.5}
          pl={3}
          sx={{
            flexGrow: 1,
            height: "100%",
            width: "-webkit-fill-available",
            boxSizing: "border-box",
          }}
        >
          <LandingHeader />
          <MuiDatePicker />
          <TimezoneSelectionArea />
        </Stack>
      </Box>
      <CustomSnackbar />
    </ThemeProvider>
  );
}
