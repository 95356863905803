import { useSetRecoilState } from "recoil";
import { snackPackState } from "../state/state";

export const useAppendSnack = () => {
  const setSnackPack = useSetRecoilState(snackPackState);

  return (message) => {
    // append message to SnackBar's message queue
    setSnackPack((prev) => [...prev, { message, key: new Date().getTime() }]);
  };
};
