import * as React from "react";
import { useRecoilValue } from "recoil";
import { DateTime } from "luxon";
import { TableBody, TableCell, TableRow } from "@mui/material";
import { style } from "../theme";
import { generateTimeArray, generateDateString } from "../lib/timeUtils";
import { sortedSlotsSelector } from "../state/slots";
import { colors } from "../lib/colors";
import { timeFormatState } from "../state/settings";
import { getSortedSlotsFromDecodedData } from "../lib/utils";

export function FilteredTimeTableBody(props) {
  let {
    host,
    guests,
    handleRowClick,
    currentDateTimeObj,
    readOnly = false,
    statesFromUrl = null,
  } = props;

  const sortedSlotsRecoil = useRecoilValue(sortedSlotsSelector);
  let sortedSlots = sortedSlotsRecoil;

  // support /share URL in read only mode
  if (readOnly && statesFromUrl !== null) {
    host = statesFromUrl.host;
    guests = statesFromUrl.guests;
    sortedSlots = getSortedSlotsFromDecodedData(statesFromUrl);
  }

  const timeFormat = useRecoilValue(timeFormatState);

  const generateTimeSlotString = (slot, timezone) => {
    return `${DateTime.fromISO(slot[0], { zone: timezone }).toFormat(
      timeFormat
    )} - ${DateTime.fromISO(slot[1], { zone: timezone }).toFormat(timeFormat)}`;
  };

  const slotsGroupedByDates = sortedSlots.reduce((group, slot) => {
    const category = generateDateString(slot[0], host.timezone.timezone);
    group[category] = group[category] ?? [];
    group[category].push(slot);
    return group;
  }, {});

  const getHostTimeArray = (dateString) => {
    const startDate = DateTime.fromISO(dateString).setZone(
      host.timezone.timezone
    );
    return generateTimeArray({
      startDate: startDate,
      currentDateTime: currentDateTimeObj,
      interval: { minutes: 60 },
      timeFormat,
    });
  };

  const handleFilteredSlotClick = (slotDate) => {
    const hostTimeArray = getHostTimeArray(slotDate);
    const hostTime = hostTimeArray.filter(
      (i) =>
        i.timeInZone.toFormat(timeFormat) ===
        DateTime.fromISO(slotDate, { zone: host.timezone.timezone }).toFormat(
          timeFormat
        )
    );
    handleRowClick(hostTime[0]);
  };

  return (
    <TableBody>
      {Object.keys(slotsGroupedByDates).map((date) => {
        const groupedSlotsByDate = slotsGroupedByDates[date];
        const hostISODate = groupedSlotsByDate[0][0];

        return (
          <React.Fragment key={date}>
            <TableRow>
              <TableCell
                align="center"
                sx={{
                  ...style.firstTableColumn,
                  backgroundColor: "#fff",
                  p: 1,
                }}
              >
                {date}
              </TableCell>
              {guests.map((guest) => (
                <TableCell key={guest.id} align="center" sx={{ p: 1 }}>
                  {generateDateString(hostISODate, guest.timezone.timezone)}
                </TableCell>
              ))}
              <TableCell></TableCell>
            </TableRow>
            {groupedSlotsByDate.map((slot, index) => (
              <TableRow
                key={slot[0]}
                {...(readOnly
                  ? {}
                  : { onClick: () => handleFilteredSlotClick(slot[0]) })}
                sx={{
                  backgroundColor: colors.timeSlot.filteredSlot,
                  cursor: readOnly ? "auto" : "pointer",
                  "& .MuiTableCell-body": {
                    borderBottom:
                      index === groupedSlotsByDate.length - 1
                        ? 0
                        : "5px solid #fff",
                  },
                }}
              >
                <TableCell
                  align="center"
                  sx={{
                    ...style.firstTableColumn,
                    backgroundColor: colors.timeSlot.filteredSlot,
                    ...style.filteredViewColumn,
                  }}
                >
                  {generateTimeSlotString(slot, host.timezone.timezone)}
                </TableCell>
                {guests.map((guest) => (
                  <TableCell
                    key={guest.id}
                    align="center"
                    sx={style.filteredViewColumn}
                  >
                    {generateTimeSlotString(slot, guest.timezone.timezone)}
                  </TableCell>
                ))}
                <TableCell
                  sx={{ backgroundColor: colors.timeSlot.past }}
                ></TableCell>
              </TableRow>
            ))}
          </React.Fragment>
        );
      })}
    </TableBody>
  );
}
